/**
 * Yeti Giveaway Landing Page
 */

import React, { Component } from "react"
import { Helmet } from 'react-helmet'
import { StaticImage } from "gatsby-plugin-image"
import WufooForm from "react-wufoo-embed"

import LayoutLander from "../components/layoutLander"
import Seo from "../components/seo"
import "../scss/pages/yetigiveaway.scss"

class YetiGiveaway extends Component {

  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll(
      "main h2, main h3, main p, main .btn, .card__desc, .hero__title"
    )
    regElements.forEach(function (el) {
      el.innerHTML = el.innerHTML.replace(/®/g, '<sup>&reg;</sup>');
    })
  }

  render() {

    return (
      <LayoutLander bodyClass="yetigiveaway" showHeader={false} showFooter={false}>
        <Helmet>
          <meta name="theme-color" content="#0C9F50" />
        </Helmet>
        <Seo
          title="YETI® Cooler Giveaway"
          description="Win a YETI® Tundra® 65 Hard Cooler from SIMPAS-applied Solutions."
		      robots="noindex, nofollow"
        />

        <header className="yetigiveaway-header hero bg--green-dark bg-img">
          <div className="container text-center flow--thick">
            <div className="row">
              <div className="col img-col img-left">
                  <StaticImage
                    src="../images/landing/yetigiveaway/logo_simpas_trimble_white.png"
                    alt="SIMPAS logo"
                    className="gs__img"
                  />
              </div>
              <div className="col img-col img-right">
                  <StaticImage
                    src="../images/landing/yetigiveaway/logo_PTx_white.png"
                    alt="PTx Trimble logo"
                    className="gs__img"
                  />
              </div>
            </div>
          </div>
        </header>

        <section className="yetigiveaway-hero bg--green">
          <div className="container flow--thick">
            <div className="row">
              <div className="col col-6 text-left">
                <h1>Win a YETI<sup>®</sup> Tundra<sup>®</sup> 65 Hard&nbsp;Cooler</h1>
              </div>
              <div className="col col-6 img-col">
                  <StaticImage
                    src="../images/landing/yetigiveaway/yeti-cooler.png"
                    alt="A white YETI® Cooler with SIMPAS branding."
                    className="gs__img"
                  />
              </div>
            </div>
          </div>
        </section>

        <section className="yetigiveaway-content bg--green">
          <div className="container flow--thick">
            <div className="row">
              <div className="col col-12 text-left">
                <p>Precision Planting® and SIMPAS® are giving away a YETI® cooler to one lucky winner per&nbsp;tour.</p>
                <p>Fill out and submit the form below for your chance to win. Each tour's winner will be announced at the conclusion of each tour. You must be present to&nbsp;win.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="yetigiveaway-form bg--green contact-content">
          <div className="container flow">
              <div className="form-wrapper">
                <WufooForm
                  userName="archermalmo"
                  formHash="q1qoxpyt1gs4cjr"
                  header="hide"
                  />
                <p className="form-policy-link">Review our <a href="https://www.amvac.com/privacy-statement" target="_blank" rel="noreferrer">privacy policy</a></p>
                <p>*Required fields</p>
              </div>
          </div>
        </section>

        <section className="yetigiveaway-rules bg--green">
          <div className="container flow">
            <div className="row">
              <div className="col col-md-12 text-left">
                <h2>Giveaway Rules</h2>
                <p>This giveaway is limited to the Precision Planting Field Days attendees at PTI Farm in Pontiac, IL. The events begin on July 29 and end on August 16,&nbsp;2024.</p>
                <ul>
                  <li>Winners must be present to win and be 18&nbsp;or&nbsp;older.</li>
                  <li>No purchase is required.</li>
                  <li>Only one entry per person, per email address is&nbsp;allowed.</li>
                  <li>A third-party agency in Memphis, Tennessee, will randomly select the&nbsp;winners.</li>
                  <li>Draws will be made at 4:00 p.m. and 5:30 p.m. daily from July 29 to August 2 and August 12 to 16,&nbsp;2024.</li>
                  <li>A total of twenty (20) coolers will be given&nbsp;away.</li>
                  <li>Odds of winning are dependent on the number of entries&nbsp;received.</li>
                  <li>Approximate retail value of each prize is&nbsp;$525.</li>
                  <li>Prizes are not transferable, and substitutions are prohibited except at the sponsors'&nbsp;discretion.</li>
                  <li>Prizes have no cash&nbsp;value.</li>
                  <li>Prize will be shipped via ground courier within two weeks of the winners' selection at no cost to the&nbsp;winner.</li>
                  <li>Winners are responsible for taxes and any other costs associated with claiming&nbsp;prizes.</li>
                  <li>For a list of all winners, email info@simpas.com.</li>
                  <li>Void where prohibited by&nbsp;law.</li>
                </ul>
                <p className="policy-link"><a href="https://www.amvac.com/privacy-statement" target="_blank" rel="noreferrer">Review our privacy policy</a></p>
              </div>
            </div>
          </div>
        </section>

        <section className="yetigiveaway-divider bg--green">
          <div className="container flow">
            <hr/>
          </div>
        </section>

        <footer className="yetigiveaway-disclaimer bg--green">
          <div className="container flow">
            <div className="row">
              <p>©2024 All rights reserved. AMVAC Chemical Corporation is a wholly owned subsidiary of American Vanguard Corporation and Precision Planting is an AGCO company.</p>
              <p>SIMPAS®, SIMPAS-applied Solutions® and respective logos are trademarks owned by AMVAC Chemical Corporation; Precision Planting® is a trademark owned by Precision Planting LLC; and YETI® is a trademark owned by YETI® Coolers&nbsp;LLC</p>
            </div>
          </div>
        </footer>

      </LayoutLander>
    )
  }
}
export default YetiGiveaway
