import * as React from "react"
import Footer from "./footer"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "../scss/main.scss"

const LayoutLander = ({ children, bodyClass, loggedTF, showHeader = true, showFooter = true }) => {
  return (
    <div className={loggedTF}>

      {showHeader && 
      <header className="lander-header">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col" style={{ position: "relative" }}>
              <Link to="/" className="header__logo-link" aria-label="Simpas Applied Solutions - Home">
                <StaticImage
                  src="../images/header-logo-SaS.png"
                  alt="Simpas Applied Solutions logo"
                  className="header__logo"
                  placeholder="blurred"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      </header>
      }

      <main className={bodyClass}>
        {children}
      </main>

      {showFooter && 
      <Footer />
      }
      
    </div>
  )
}

export default LayoutLander
